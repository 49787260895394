<template>
  <v-dialog v-model="dialog" max-width="800">
    <perseu-card :title="titleDialog">
      <template slot="content">
        <v-form ref="form" @submit.prevent="save">
          <v-text-field
            label="Nome"
            v-model.trim="type.name"
            :rules="[$rules.required]"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn @click="save" color="primary">Salvar</v-btn>
      </template>
    </perseu-card>
  </v-dialog>
</template>

<script>
import { saveType } from "@/services/oligoelements-service";
export default {
  data: () => ({
    type: {},
    dialog: false,
  }),
  computed: {
    titleDialog() {
      return (this.type.id ? "Editar " : "Novo ") + "Tipo";
    },
  },
  methods: {
    create() {
      this.type = {};
      this.$refs?.form?.reset();
      this.dialog = true;
    },
    edit(item) {
      this.type = item;
      this.dialog = true;
    },

    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.form.validate()) return;
        const { data } = await saveType(this.type);
        if (this.type.id) {
          this.$emit("update", data);
        } else {
          this.$emit("create", data);
        }
        this.$toasted.global.defaultSuccess();
        this.dialog = false;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
